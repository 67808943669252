import * as React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import { useStaticQuery, graphql } from "gatsby"

const DocumentPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulResourcePage {
        documentSection {
          fileUpload {
            file {
              url
            }
            title
          }
        }
      }
    }
  `)
  const docContent = data.contentfulResourcePage.documentSection.fileUpload;
  return (
    <Layout>
      <SEO title="Resources" />
      <div className="sidebar-page-container">
        <div className="services-section">
          <div className="auto-container">
            <div className="sec-title">
              <div className="title">tekki fii</div>
              <h2><span>Resources </span>Document</h2>
            </div>
            <div className="inner-container">
              <div className="video">
                <div className="tab-content">
                  <div className="row g-3">
                    {docContent.map(({ file, title }, index) => (
                      <div className="video-block col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                        <a href={file.url} target="_blank" rel="noreferrer">
                          <div className="card text-white shadow-sm">
                            <div className="card-body-doc card-content">
                              <div className="card-type">
                                <a href={file.url} target="_blank" rel="noreferrer">
                                  <i className="fa fa-file-pdf doc-icon"></i>
                                </a>
                                <h4 className="card-title-header mx-auto">{title}</h4>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div >
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default DocumentPage

